import React from "react";
import { Link } from "gatsby";
import "./pagination.css";

const Pagination = ( {currentPageNum, pageCount, pageName}) => {


    let prevPage;
    if (pageName === "page") {
      prevPage = currentPageNum - 1 === 1 ? `/` : `/${pageName}/${currentPageNum - 1}/`;
    } else {
      prevPage = currentPageNum - 1 === 1 ? `/${pageName}/` : `/${pageName}/${currentPageNum - 1}/`;
    } 
    const nextPage = `/${pageName}/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className="pagination-container">
        {!isFirstPage && <Link to={prevPage} className="pagination-link">Previous</Link>}
        {!isFirstPage && pageCount === 1 && [...Array(pageCount)].map((_val, index) => {
          const pageNum = index + 1;
          return (
            <Link
              key={`listing-page-${pageNum}`}
              to={pageNum === 1 ? `/${pageName}/` : `/${pageName}/${pageNum}/`}
              className="pagination-link"
            >
              {pageNum}
            </Link>
          );
        })}
        {!isLastPage && <Link to={nextPage} className="pagination-link">{pageName === "page" ? "More Posts" : "Next"}</Link>}
      </div>
    );
}

export default Pagination;