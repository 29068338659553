import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../layout";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import ExpandedPostPreview from "../../components/ExpandedPostPreview/ExpandedPostPreview";
import MailingList from "../../components/MailingList/mailingList";
import Pagination from "../../components/Pagination/pagination";


import "./Landing.css";


function Landing({ pageContext, data }) {
  const postEdges = data.allMarkdownRemark.edges;
  const firstEdge = postEdges[0];
  return (
    <Layout>
      <div className="landing-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        { pageContext.currentPageNum === 1 &&
        <div className="landing-header-text">
          <h1>Take Control of Your Money.</h1>
          <h1>Finance Your Future.</h1>
          <p>Demystify money and set yourself on a path to exponentially increase your wealth.</p>
          <a href="#mailing-list">Join the Newsletter</a>
        </div>
        }
        {
          postEdges.map((post) => (
            <ExpandedPostPreview post={post} />
          ))
        }
        <Pagination currentPageNum={pageContext.currentPageNum} pageCount={pageContext.pageCount} pageName="page"/>
        <MailingList/>
        <br></br>
        <span id="mailing-list"></span>
      </div>
    </Layout>
  );
}

export default Landing;

/* eslint no-undef: "off" */
export const landingQuery = graphql`
  query LandingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED 
                  height:350
                )
              }
            }
            date
            category
            coverAlt
            description
          }
        }
      }
    }
  }
`;
