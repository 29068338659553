import  React  from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

import './ExpandedPostPreview.css';

const ExpandedPostPreview = ({post}) => {
    return(
        <Link to={post.node.fields.slug} key={post.node.frontmatter.title} className="featured">
            <GatsbyImage image={getImage(post.node.frontmatter.cover)} className="featuredImage" alt={`${post.node.frontmatter.title} post preview`}/>
            <div className="featuredDetails">
            <div className="featuredTags">
                <p className="category">{post.node.frontmatter.category}</p>
            </div>
            <h2>{post.node.frontmatter.title}</h2>
            <p>
                {post.node.excerpt}
            </p>
            </div>
        </Link>
    );

}

export default ExpandedPostPreview;